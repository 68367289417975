/* eslint-disable */
"use client"
import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { color, motion } from 'framer-motion';
import { Button } from "@/components/ui/button";
import { UserButton, useAuth } from "@clerk/nextjs";
import Link from "next/link";
import { ArrowRight, LogIn, CornerDownRight } from "lucide-react";
import axios from "axios";
import { useToast } from "@/components/ui/use-toast";
import { useRouter } from "next/navigation";
import StreamPlayer from '@/components/StreamPlayer';
import PdfUrlProcessor from '@/components/pdfUrlFast';
import VideoModal from '@/components/VideoModal';
import { SampleSelector } from '@/components/SampleSelector';

// TO DO: Update with Win's Design
const HomePage = () => {
  const [email, setEmail] = useState('');
  const { toast } = useToast();
  const router = useRouter();
  const { isLoaded, userId, sessionId, getToken } = useAuth();
  const customerCode = 'sd7qeqr9yk4jkxbb'; // Cloudflare customer code
  const overview = 'f4c43a3fa6003bb9a06ee0e22b7455a7';
  const translateVid = 'f03e711b2237d06deeea4531b2e3eb8e';
  const createVid = '87441beed286ec0163c12dc36a610610';
  const discoverVid = '15c7061cac005c387df940378e086749';

  const languages = [
    "Chinese", "Spanish", "Japanese", "French", "Korean", "Italian", "Arabic",
    "Russian", "Portuguese", "German", "Vietnamese", "English", "Swahili",
    "Polish", "Hindi", "Hebrew", "Swedish", "Tagalog", "Norwegian", "Thai",
    "Farsi", "Dutch", "Bahasa"
  ];

  const powerWords = [
    "Genius", "Culture", "People", "Coworkers"
  ]

  const [currentLanguage, setCurrentLanguage] = useState(languages[0]);
  const [nextLanguage, setNextLanguage] = useState(languages[1]);
  const [index, setIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setIndex(prevIndex => (prevIndex + 1) % languages.length);
        setIsAnimating(false);
      }, 1000); // Match the duration of the animation
    }, 3000); // Change language every 3 seconds (2 seconds wait + 1 second animation)

    return () => clearInterval(interval);
  }, [languages.length]);

  useEffect(() => {
    setCurrentLanguage(languages[index]);
    setNextLanguage(languages[(index + 1) % languages.length]);
  }, [index, languages]);


  const [faqsOpen, setFaqsOpen] = useState<{ [key: number]: boolean }>({});

  const toggleFaq = (index: number) => {
    setFaqsOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  //Email Submission

  const validateEmail = (email: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  //Email Submission
  const handleEmailSubmission = async (email: string) => {
    if (!validateEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }
    try {
      const response = await axios.post("/api/add-email", { email });
      console.log("response:", response)
      if (response.status === 200) {
        toast({
          title: "You're in!",
          description: "We'll keep you updated on our progress.",
        });
      } else {
        toast({
          title: "Oh no!",
          description: "There was an error 😞",
        });
      }
    } catch (error) {
      toast({
        title: "Oh no!",
        description: "There was an error 😞",
      });
    } finally {
      toast({
        title: "You're in!",
        description: "We'll keep you updated on our progress.",
      });
      setEmail(""); // Clear the input field in the finally block
    }
  };

  // Auto login to library
  // useEffect(() => {
  //   const checkUserSubscription = async () => {
  //     if (userId) {
  //       try {
  //         const response = await axios.get('/api/check-subscription');
  //         if (response.data.isValid) {
  //           router.push('/library');
  //         }
  //       } catch (error) {
  //         console.error('Error checking subscription:', error);
  //       }
  //     }
  //   };

  //   checkUserSubscription();
  // }, [userId, router]);

  // Sticky CTA
  useEffect(() => {
    const handleScroll = () => {
      // Adjust this value to control when the CTA becomes sticky
      const scrollThreshold = 200; // Example: 200px from the top 
      setIsSticky(window.scrollY > scrollThreshold);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  {/* <div className="mb-4 text-white">
                <Image
                  src="/image/negative_logo.svg"
                  alt="N_Logo"
                  width={300}
                  height={100}
                />
              </div> */}


  return (
    <div className="bg-white text-[#4A4E69] w-full font-inter">
      {/* Header */}
      <header className="gradient-header rounded-b-[6rem] pt-4 relative overflow-hidden">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center">
              <Image
                src="/lumi-logo.svg"
                alt="Lumi Logo"
                width={96}
                height={48}
              />
            </div>

            <Link href="/sign-in">
              <Button className="text-white font-bold py-2 px-4 rounded animated-gradient">
                Login <LogIn className="w-4 h-4 ml-2" />
              </Button>
            </Link>
          </div>

          <div className="flex flex-col justify-center items-center text-white" style={{ minHeight: 'calc(100vh - 200px)' }}>
            <div className="mb-4 text-white">
              <Image
                src="/rating_w.svg"
                alt="Rating"
                width={300}
                height={100}
              />
            </div>
            <h2 className="text-2xl sm:text-6xl font-bold mb-4 text-center flex flex-wrap justify-center items-center">
              {/* TODO: Add rotation on genius */}
              Connect With Your Genius
              
            </h2>
            
            <div className="w-full max-w-md mt-8">
              <PdfUrlProcessor />
            </div>
            {/* New button for Aesop's Fables */}
            <div className="mt-4 w-full max-w-md">
              <SampleSelector />
              <div><p className="text-sm text-center italic">(p.s. this is super fast 🤯)</p></div>
            </div>
          </div>
        </div>
      </header>

{/* How It Works section */}
<div className="relative z-10 flex justify-center" style={{ marginTop: '-50px' }}>
      <div 
        className="cursor-pointer hover:scale-105 transition-transform duration-300"
        onClick={() => setIsVideoModalOpen(true)}
      >
        <Image
          src="/image/how_it_works.png"
          alt="How It Works"
          width={200}
          height={200}
        />
      </div>
    </div>

    {/* VideoModal component */}
    <VideoModal
      isOpen={isVideoModalOpen}
      onClose={() => setIsVideoModalOpen(false)}
      videoUid={overview}
      customerCode={customerCode}
    />


<main className="">
{/* Hero Section */}
<section className="py-10 w-full text-[#22223B] relative overflow-hidden">
  <div className="w-full z-10 relative"> 
    <div className="max-w-full text-center"> 
      <h2 className="text-4xl sm:text-6xl font-bold mb-4 text-center flex flex-wrap justify-center items-center">
      <span className="mr-2">Read in</span>
              <div className="relative inline-block language-container" style={{ top: '-2px', left: '-6px' }}>
                <span className={`absolute ${isAnimating ? 'animate-roll-out' : ''}`}>
                  {currentLanguage}
                </span>
                <span className={`absolute ${isAnimating ? 'animate-roll-in' : 'hidden'}`}>
                  {nextLanguage}
                </span>
              </div>
              </h2>
      <div className="max-w-3xl mx-auto">
        <p className="text-lg md:text-xl leading-relaxed mb-10">
          Lumi enables you to turn your favorite strories in to language learning material. Listen and read for 30 minutes a day to rapidly improve your language skills.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-4 items-center mb-10"> 
        {/* Increased gap for larger screens */}
        <div className="mx-4">
          <StreamPlayer videoUid={overview} customerCode={customerCode} tiltDirection="left" />
        </div>
        <div className="w-xl ml-2 mr-16 text-left"> {/* Align text to the left */}
          <h3 className="text-3xl font-bold mb-4">Start Reading in a New Language Within 2 Minutes</h3>
          <p className="text-lg leading-relaxed mb-8">
            Choose from our library of classics or upload your own text. Pick your desired reading level so Lumi can personalize your text.
          </p>
          <h3 className="text-3xl font-bold mb-4">Personalize Your Favorite Book</h3>
          <p className="text-lg leading-relaxed mb-8">
            Lumi uses AI to instantly present your chosen story, simplified and translated to match your interests and proficiency.
          </p>
          <h3 className="text-3xl font-bold mb-4">Read with Smart Tools</h3>
          <p className="text-lg leading-relaxed mb-8">
            Enhance your reading experience with synchronized audio, native pronunciations, and instant translations of any word or phrase to boost comprehension and pronunciation skills.
          </p>
          <h3 className="text-3xl font-bold mb-4">Practice with Luna</h3>
          <p className="text-lg leading-relaxed">
            Engage with Luna, our AI language tutor, who provides personalized practice sessions tailored to your interests and proficiency level.
          </p>
        </div>
      </div>
      <Link href="/sign-up">
        <Button className="relative overflow-hidden w-full sm:w-auto h-full animated-gradient px-8 py-3 text-lg font-bold rounded-lg">
          Start Your Journey <ArrowRight className="w-4 h-4 ml-2" />
        </Button>
      </Link>
    </div>
  </div>
</section>
      
<section className="py-16 bg-[#F2E9E4] w-full mx-auto">
  <div className="container mx-auto px-4 md:px-8 lg:px-16 xl:px-32 z-10 relative">
    <h2 className="text-5xl md:text-6xl text-black text-center font-bold mb-8">
      More Reasons to Love Lumi
    </h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <div className="bg-[#22223B] p-10 rounded-lg text-white text-center shadow-md flex flex-col items-center">
        <div className="mb-6">
          <Image
            src="/image/book.png"
            alt="Read in over 30 languages"
            width={64}
            height={64}
            className="mx-auto"
          />
        </div>
        <div className="mb-4">
          <h3 className="text-2xl font-bold">Read in over 30 languages</h3>
        </div>
        <div>
          <p className="text-lg">Expand your horizons with our multilingual support.</p>
        </div>
      </div>

      <div className="bg-[#22223B] p-10 rounded-lg text-white text-center shadow-md flex flex-col items-center">
        <div className="mb-6">
          <Image
            src="/image/compscreen.png"
            alt="Read while listening with voice"
            width={80}
            height={80}
            className="mx-auto"
          />
        </div>
        <div className="mb-4">
          <h3 className="text-2xl font-bold">Read while listening with voice</h3>
        </div>
        <div>
          <p className="text-lg">2X your input with synchronized audio and text.</p>
        </div>
      </div>

      <div className="bg-[#22223B] p-10 rounded-lg text-white text-center shadow-md flex flex-col items-center">
        <div className="mb-6">
          <Image
            src="/image/chatbubble.png"
            alt="Chat with our AI Language Tutor Luna"
            width={80}
            height={80}
            className="mx-auto"
          />
        </div>
        <div className="mb-4">
          <h3 className="text-2xl font-bold">Chat with our AI Language Tutor Luna</h3>
        </div>
        <div>
          <p className="text-lg">Get personalized conversation practice anytime.</p>
        </div>
      </div>

      <div className="bg-[#22223B] p-10 rounded-lg text-white text-center shadow-md flex flex-col items-center">
        <div className="mb-6">
          <Image
            src="/image/multilingualbook.png"
            alt="Read classics in your language"
            width={80}
            height={80}
            className="mx-auto"
          />
        </div>
        <div className="mb-4">
          <h3 className="text-2xl font-bold">Read classics in your language</h3>
        </div>
        <div>
          <p className="text-lg">Explore an extensive library of simplified books.</p>
        </div>
      </div>

      <div className="bg-[#22223B] p-10 rounded-lg text-white text-center shadow-md flex flex-col items-center">
        <div className="mb-6">
          <Image
            src="/image/hands.png"
            alt="Translate and simplify any text"
            width={100}
            height={100}
            className="mx-auto py-6"
          />
        </div>
        <div className="mb-4">
          <h3 className="text-2xl font-bold">Translate and simplify any text</h3>
        </div>
        <div>
          <p className="text-lg">Choose your reading level and let AI customize your experience.</p>
        </div>
      </div>

      <div className="bg-[#22223B] p-10 rounded-lg text-white text-center shadow-md flex flex-col items-center">
        <div className="mb-6">
          <Image
            src="/image/bookclub.png"
            alt="Join a community"
            width={80}
            height={80}
            className="mx-auto"
          />
        </div>
        <div className="mb-4">
          <h3 className="text-2xl font-bold">Join a community</h3>
        </div>
        <div>
          <p className="text-lg">Stay motivated with like-minded learners.</p>
        </div>
      </div>
    </div>
  </div>
</section>

  
        {/* Detailed Product Features Section */}

        {/* <section className="py-16 mx-8">
          <h2 className="text-4xl text-center font-bold">Read <b>Anything</b><br />in <b>Any</b> Language </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">
            <div>
              <p className="mt-4">
                <b>Tired of struggling to understand native speakers?</b> Wish you could pick apart the nuances of a language without getting bogged down?
              </p>
              <p className="mt-4">
                <b>Lumi Reader</b> is your key to unlocking fluency. Dive into authentic content in over 30 languages and experience a whole new level of comprehension.
              </p>
              <p className="mt-4">
                <b>Instantly translate any word or phrase with a simple click.</b>  Hear native pronunciations and even see helpful images to solidify your understanding.
              </p>
              <p className="mt-4">
                <b>Stop memorizing and start truly understanding</b> Immerse yourself in the beauty of learning with stories!
              </p>
            </div>
            <div>
              <StreamPlayer videoUid={translateVid} customerCode={customerCode} tiltDirection="right" />
            </div>
          </div>
        </section> */}
{/* 
        <section className="py-16 mx-8">
          <h2 className="text-4xl text-center  font-bold">Upload and Learn From <br /><b>Your Favorite Stories</b></h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">
            <div>
              <StreamPlayer videoUid={createVid} customerCode={customerCode} tiltDirection="left" />
            </div>
            <div>
              <p className="mt-4">
                <b>Wish you could read your favorite books in your target language?</b> Or study complex subjects without language barriers?
              </p>
              <p className="mt-4">
                <b>Now you can!</b> Simply upload any PDF and we'll transform it into a personalized graded reader, tailored to your language and level.
              </p>
              <p className="mt-4">
                <b>Choose from over 30 languages and various difficulty levels.</b> Master new vocabulary, grammar, and reading comprehension with ease.
              </p>
              <p className="mt-4">
                <b>Unlock the world of knowledge and expand your learning horizons with personalized graded readers.</b> Start reading today!
              </p>
            </div>
          </div>
        </section>

        <section className="py-16 mx-8">
          <h2 className="text-4xl text-center font-bold">Unlock the World of Stories<br /><b>Effortlessly</b></h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">
            <div>
              <p className="mt-4">
                Ever wanted to read incredible books but found them <b>too challenging</b> in another language? We've made it easy!
              </p>
              <p className="mt-4">
                Explore a library of <b>pre-simplified</b> classics, from captivating novels to timeless tales, ready to enjoy at your level.
              </p>
              <p className="mt-4">
                Choose from over 30 languages and dive into stories like The Great Gatsby or Aesop's Fables, <b>without feeling overwhelmed</b>.
              </p>
              <p className="mt-4">
                <b>Boost your confidence</b> , expand your vocabulary, and fall in love with reading in your target language. Start exploring today!
              </p>
            </div>
            <div>
              <StreamPlayer videoUid={discoverVid} customerCode={customerCode} tiltDirection="right" />
            </div>
          </div>
        </section> */}



        {/* Testimonials Section */}
        <section className="py-16 mx-8">
          <h2 className="text-4xl font-bold text-center mb-8">What Learners Have to Say</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Testimonial 1 */}
            <div className="bg-[#EFEFEF] p-8 rounded-lg text-gray-800 shadow-md">
              <p>“I struggled with language apps and classes for over two years, but I never felt truly fluent. Discovering Lumi was a game-changer. The ability to simplify and translate books, read while listening, and use the AI tutor has made my learning experience incredibly effective and enjoyable. The personalized approach kept me engaged and motivated. I highly recommend Lumi to anyone serious about learning a new language.”</p>
              <p className="mt-6 font-bold">Carlos</p>
              <p className="text-sm">Spanish Learner</p>
            </div>

            {/* Testimonial 2 */}
            <div className="bg-[#EFEFEF] p-8 rounded-lg text-gray-800 shadow-md">
            <p>“Learning a new language was always intimidating for me. I had tried multiple apps and courses but never made significant progress. This app changed that completely. The ability to read simplified and translated books, listen along, and use the AI tutor has transformed my learning journey. After three weeks, my comprehension and confidence in Italian have soared. I'm excited about learning again and highly recommend this app to anyone looking to make real progress.”</p>
            <p className="mt-6 font-bold">Irene</p>
            <p className="text-sm">Italian Learner</p>
            </div>

            {/* Testimonial 3 */}
            <div className="bg-[#EFEFEF] p-8 rounded-lg text-gray-800 shadow-md">
              <p>“I had been studying Japanese on various apps for years but never felt truly proficient. Since using Lumi, my learning has accelerated beyond my expectations. The combination of book translations, AI-assisted reading, and the personalized tutor has made a huge difference. I now have a clear plan and effective tools to achieve fluency. This app has not only enhanced my language skills but also improved my overall learning habits. I couldn't be happier with the results.”</p>
              <p className="mt-6 font-bold">Sophia K</p>
              <p className="text-sm">Japanese Learner</p>
            </div>

            {/* Testimonial 4 */}
            <div className="bg-[#EFEFEF] p-8 rounded-lg text-gray-800 shadow-md">
              <p>“This app has been a game-changer for my Chinese learning journey. The Lumi team genuinely cares about user success and their innovative approach makes learning sustainable and enjoyable. I look forward to each session because it feels less like studying and more like a fun challenge. I highly recommend Lumi to anyone wanting a truly effective and enjoyable language learning experience. Thank you, Lumi team!”</p>
              <p className="mt-6 font-bold">Alex</p>
              <p className="text-sm">Chinese Learner</p>
            </div>

          </div>
        </section>



        {/* Approach is backed by leading research in linguistics */}
        <section className="py-16 gradient-header rounded-[6rem] shadow-md text-center">
          <h2 className="text-4xl font-bold text-[#EFEFEF] mb-4">
            <div className="text-center">
              Lumi incorporates the best <br />
              of Linguistics research
            </div>
          </h2>
          <p className="mt-4 text-lg text-[#EFEFEF]">
            <span className="font-bold">Stop wasting time and energy</span> on outdated, ineffective methods.
          </p>
          <p className="mt-4 text-lg text-[#EFEFEF]">
            By leveraging the power of Comprehensible Input and Reading While Listening, you can immerse yourself in your target language from day one.<br />
            These proven methods help you understand and internalize the language faster and more effectively:
          </p>
          <div className="mx-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-8">
            <div className="bg-[#F2E9E4] text-[#22223B] p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-bold mb-2">Immediate Exposure</h3>
              <p>Dive into the natural use of the language, helping you think in the language rather than translating from your native tongue.</p>
            </div>
            <div className="bg-[#F2E9E4] text-[#22223B] p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-bold mb-2">Pattern Recognition</h3>
              <p>Your brain starts recognizing language patterns, essential for mastering grammar and vocabulary.</p>
            </div>
            <div className="bg-[#F2E9E4] text-[#22223B] p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-bold mb-2">Enhanced Retention</h3>
              <p>Dual input from reading and listening reinforces memory retention and recall.</p>
            </div>
            <div className="bg-[#F2E9E4] text-[#22223B] p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-bold mb-2">Engaging Learning</h3>
              <p>Stay motivated with an enjoyable and immersive learning experience.</p>
            </div>
          </div>
          <p className="mt-8 text-lg text-[#EFEFEF]">
            Experience a faster, easier, and more effective way to learn a language. Start your journey with us today!
          </p>
          <div className="flex justify-center py-4">
            <Link href="/sign-up">
              <Button className="text-white animated-gradient py-4 px-8 rounded-lg text-2xl">
                Learn More Effectively <ArrowRight className="w-4 h-4 ml-2" />
              </Button>
            </Link>
          </div>
        </section>

        {/* Frequently Asked Questions Section */}
        <section className="py-16">
          <h2 className="text-4xl font-bold text-center mb-8 text-[#4A4E69]">Frequently asked questions</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mx-8">
            {/* FAQ Item 1 */}
            <div className="bg-[#EFEFEF] p-6 rounded-lg shadow-md">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFaq(1)}
              >
                <h3 className="text-xl font-semibold text-gray-800">I&apos;m not an absolute beginner. Is it right for me?</h3>
                <span className="text-2xl text-gray-600">{faqsOpen[1] ? '-' : '+'}</span>
              </div>
              {faqsOpen[1] && (
                <p className="mt-4 text-gray-700">
                  Yes, this app is designed to help learners of all levels, from beginners to intermediate and beyond.
                </p>
              )}
            </div>

            {/* FAQ Item 2 */}
            <div className="bg-[#EFEFEF] p-6 rounded-lg shadow-md">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFaq(2)}
              >
                <h3 className="text-xl font-semibold text-gray-800">How long will it take to see results?</h3>
                <span className="text-2xl text-gray-600">{faqsOpen[2] ? '-' : '+'}</span>
              </div>
              {faqsOpen[2] && (
                <p className="mt-4 text-gray-700">
                  Results vary depending on the individual, but many learners start to see significant progress within a few months of consistent practice.
                </p>
              )}
            </div>

            {/* FAQ Item 3 */}
            <div className="bg-[#EFEFEF] p-6 rounded-lg shadow-md">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFaq(3)}
              >
                <h3 className="text-xl font-semibold text-gray-800">Can I use my own books with Lumi?</h3>
                <span className="text-2xl text-gray-600">{faqsOpen[3] ? '-' : '+'}</span>
              </div>
              {faqsOpen[3] && (
                <p className="mt-4 text-gray-700">
                  Yes, you can upload your own books and choose the reading level and language you want to read them in.
                </p>
              )}
            </div>

            {/* FAQ Item 4 */}
            <div className="bg-[#EFEFEF] p-6 rounded-lg shadow-md">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFaq(4)}
              >
                <h3 className="text-xl font-semibold text-gray-800">What languages are supported?</h3>
                <span className="text-2xl text-gray-600">{faqsOpen[4] ? '-' : '+'}</span>
              </div>
              {faqsOpen[4] && (
                <p className="mt-4 text-gray-700">
                  Lumi supports over 30 languages, including Chinese, Spanish, Japanese, French, Korean, Italian, Arabic, Russian, Portuguese, German, and many more.
                </p>
              )}
            </div>

            {/* FAQ Item 5 */}
            <div className="bg-[#EFEFEF] p-6 rounded-lg shadow-md">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFaq(5)}
              >
                <h3 className="text-xl font-semibold text-gray-800">Is there a money-back guarantee?</h3>
                <span className="text-2xl text-gray-600">{faqsOpen[5] ? '-' : '+'}</span>
              </div>
              {faqsOpen[5] && (
                <p className="mt-4 text-gray-700">
                  Yes, if you verify that read our recommended 10,000 words per day for 7 days within the first 30 days and are still unhappy with the product we will refund your payment.
                </p>
              )}
            </div>

            {/* FAQ Item 6 */}
            <div className="bg-[#EFEFEF] p-6 rounded-lg shadow-md">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFaq(6)}
              >
                <h3 className="text-xl font-semibold text-gray-800">How does the AI language tutor work?</h3>
                <span className="text-2xl text-gray-600">{faqsOpen[6] ? '-' : '+'}</span>
              </div>
              {faqsOpen[6] && (
                <p className="mt-4 text-gray-700">
                  Our AI language tutor, Luna, provides personalized assistance and feedback to help you improve your language skills anytime you need it.
                </p>
              )}
            </div>

            {/* FAQ Item 7 */}
            <div className="bg-[#EFEFEF] p-6 rounded-lg shadow-md">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFaq(7)}
              >
                <h3 className="text-xl font-semibold text-gray-800">Can I access Lumi on multiple devices?</h3>
                <span className="text-2xl text-gray-600">{faqsOpen[7] ? '-' : '+'}</span>
              </div>
              {faqsOpen[7] && (
                <p className="mt-4 text-gray-700">
                  Yes, Lumi can be installed on multiple devices, allowing you to learn on the go.
                </p>
              )}
            </div>

            {/* FAQ Item 8 */}
            <div className="bg-[#EFEFEF] p-6 rounded-lg shadow-md">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFaq(8)}
              >
                <h3 className="text-xl font-semibold text-gray-800">Is there a community for learners?</h3>
                <span className="text-2xl text-gray-600">{faqsOpen[8] ? '-' : '+'}</span>
              </div>
              {faqsOpen[8] && (
                <p className="mt-4 text-gray-700">
                  Yes, we're building a community of like-minded learners on <Link href="https://discord.gg/E5d56358Ev" target="_blank" rel="noopener noreferrer">Discord</Link>.
                </p>
              )}
            </div>

          </div>
        </section>

         {/* Final Call to Action Section */}
         <section className=" bg-[#22223B] rounded-t-[6rem] text-left px-4 relative">
          <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center">
            <div className="md:w-1/2">
              <p className="text-4xl font-semibold text-center text-[#EFEFEF]">
                A note from the founder, Jeremiah
              </p>
              <p className="mt-6 text-2xl text-center text-[#EFEFEF] leading-relaxed">
                "I built Lumi because I struggled with uninspiring content while learning Chinese. I hope Lumi offers an engaging and enjoyable way to support you language learning journey."
              </p>
              <div className="mt-8 flex justify-center">
                <Link href="/sign-up">
                  <Button className="animated-gradient text-white py-4 px-8 rounded-lg text-xl">
                    Try Lumi
                  </Button>
                </Link>
              </div>
            </div>
            <div className="md:w-1/2 mt-6 md:mt-0 flex justify-center">
              <Image
                src="/image/jeremiah_footer.png"
                alt="Jeremiah"
                width={400}
                height={400}
                className="rounded-lg"
              />
            </div>
          </div>
        </section>

        <section
          className={`cta-section py-2 bg-[#22223B] text-center transition-transform duration-500 transform ${isSticky ? 'fixed bottom-0 left-0 w-full' : 'relative'}`}
        >
          <Link href="/sign-up">
            <Button className="animated-gradient text-white text-xl w-full px-6 py-4 rounded-r-md">
              Start reading with AI now
            </Button>
          </Link>
        </section>

        {/* Footer Section */}
        <section className="bg-[#F2E9E4] py-8 text-center text-gray-400">
          <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-4 text-sm">
            <div className="flex items-center justify-center md:justify-start w-full md:w-auto">
              <Image
                src="/image/lumi_footer.png"
                alt="Lumi Logo"
                width={100}
                height={40}
                className="mr-4"
              />
            </div>
            <div className="flex justify-center space-x-4 w-full md:w-auto">
              <Link href="/privacy-policy" className="text-gray-600 hover:text-gray-800">
                Privacy Policy
              </Link>
              <Link href="/linkedin" className="text-gray-600 hover:text-gray-800">
                LinkedIn
              </Link>
              <Link href="/instagram" className="text-gray-600 hover:text-gray-800">
                Instagram
              </Link>
              <Link href="/twitter" className="text-gray-600 hover:text-gray-800">
                Twitter
              </Link>
              <Link href="https://lounge.lumireader.ai/" className="text-gray-600 hover:text-gray-800">
                Newsletter
              </Link>
            </div>
            <p className="text-gray-600 text-center md:text-right w-full md:w-auto">
              © LumiReader 2024. All rights reserved.
            </p>
          </div>
        </section>

      </main>
    </div>
  );
};

export default HomePage;